<template>
  <b-modal
    v-model="props.show"
    title="Preview"
    size="xl"
    centered
    @hidden="$emit('close')"
    @close="$emit('close')"
  >
    <div v-for="(versionGroup, version) in groupedItems"
         :key="version"
    >
      <div class="d-flex align-items-center mb-1">
        <b-badge variant="primary"
                 pill
                 class="mr-2"
        >
          Version {{ version }}
        </b-badge>
        <div class="flex-grow-1">
          <hr class="my-0">
        </div>
      </div>
      <div class="row g-2">
        <div
          v-for="(item, index) in versionGroup"
          :key="index"
          class="col-md-2 col-sm-3 col-6"
        >
          <div class="position-relative hover-card">
            <b-button
              variant="success"
              class="btn-icon position-absolute edit-icon"
              style="top: 2px; right: 2px; z-index: 1"
              size="sm"
              @click="handleEdit(item)"
            >
              <feather-icon icon="EditIcon"
                            size="12"
              />
            </b-button>
            <LibraryCard :item="formatCardDetail(item)"
                         :is-read-only="true"
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal v-if="!!wordDetailToEdit"
             :visible="true"
             @close="wordDetailToEdit = false"
             @hide="wordDetailToEdit = false"
    >
      <div class="card text-center p-2">
        <GeneratedWord :word="wordDetailToEdit"
                       :image-selected="generatedWordHandler.imageSelected"
                       :set-word-selector-audio="generatedWordHandler.setWordSelectorAudio"
        />
      </div>
      <template #modal-footer>
        <div>
          <b-button variant="secondary"
                    @click="wordDetailToEdit = false"
          >
            Cancel
          </b-button>
          <b-button class="ml-1"
                    variant="success"
                    :disabled="isProcessing"
                    @click="generatedWordHandler.updateLabGeneratedWord"
          >
            <b-spinner v-if="isProcessing"
                       small
            /> Submit
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-modal>
</template>

<script setup>
import {
  BModal, BBadge, BButton, BSpinner,
} from 'bootstrap-vue'
import LibraryCard from '@/views/super/image-library/components/LibraryCard.vue'
import GeneratedWord from '@/views/common/curriculum/components/Tools/GeneratedWord.vue'
import { computed, ref, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array,
    default: () => [],
  },
})

const isProcessing = ref(null)
const wordDetailToEdit = ref(null)
const emit = defineEmits(['refetch'])
const groupedItems = computed(() => props.items.reduce((acc, item) => {
  const version = item.version || 1
  if (!acc[version]) {
    acc[version] = []
  }
  acc[version].push(item)
  return acc
}, {}))

const formatCardDetail = item => ({
  vocab_word: item.word,
  audio: item.audio,
  image: item.image,
  synonyms: item.library?.synonyms || [],
})

const formatEditItem = item => ({
  word: item.word,
  id: item.id,
  audio: item.audio,
  item: item.image,
  image: item.image,
  incorrect_words: item.incorrect_words,
})

const handleEdit = item => {
  wordDetailToEdit.value = formatEditItem(item)
}
const root = getCurrentInstance().proxy.$root

const generatedWordHandler = {
  imageSelected(e, k, i, wordId, type = null, otherParm = {}) {
    if (type === 'picked') {
      useJwt.imageUploadForParagraphWords(wordId, { image: e, ...otherParm }).then(() => {
      }).catch(err => {
        console.log(err)
      })
      return
    }
    if (!e.target.files[0]) return
    // eslint-disable-next-line prefer-destructuring
    root.fileToBase64(e.target.files[0]).then(result => {
      useJwt.imageUploadForParagraphWords(wordId, { image: result }).then(response => {
        wordDetailToEdit.value = {
          ...response.data.data.word,
          image: response.data.data.word.image_url,
          audio: response.data.data.word.audio_url,

        }
      }).catch(err => {
        console.log(err)
      })
    })
  },
  setWordSelectorAudio(word, { audio }, type = null) {
    if (type === 'picked') {
      useJwt.imageUploadForParagraphWords(word.id, { audio }).then(() => {
      }).catch(err => {
        console.log(err)
      })
      return
    }
    root.fileToBase64(audio).then(result => {
      // eslint-disable-next-line no-param-reassign
      wordDetailToEdit.value.audio = result
      useJwt.imageUploadForParagraphWords(word.id, { audio: result }).then(() => {
      }).catch(err => {
        console.log(err)
      })
    })
  },
  updateLabGeneratedWord() {
    isProcessing.value = true
    useJwt.updateLabGeneratedWord(wordDetailToEdit.value.id).then(response => {
      root.showSuccessMessage(response)
      wordDetailToEdit.value = null
      emit('refetch')
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      isProcessing.value = false
    })
  },
}
</script>
<style lang="scss">
.edit-icon {
  display: none;
}

.hover-card:hover .edit-icon {
  display: block;
}
</style>
